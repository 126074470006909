@media screen and (min-width: 990px) {
  /* Make Navbar Stick to the Top */
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white; /* Adjust as needed */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }

  /* Add padding to body to prevent content from hiding behind navbar */
  body {
    padding-top: 18%; /* Adjust based on navbar height */
  }

  /* Style for Navigation Links */
  .nav-links a {
    color: black;
    text-decoration: none;
    padding: 10px 15px;
    transition: color 0.3s ease-in-out;
  }

  /* Highlight the Active Page */
  .nav-links a.active {
    color: red;
    font-weight: bold;
    border-bottom: 2px solid red; /* Optional underline effect */
  }
}

/* Media queries for Tablet  version */
@media screen and (max-width: 991px) {
  .nav-toggle {
    display: block;
    margin-right: 20px;
    color: #000;
  }
  .nav-icon {
    width: 25px;
    height: 25px;
  }

  .nav-links {
    display: none;
    /* margin-left: 120%; */
  }
  .open {
    display: flex;
    opacity: 1;
    position: absolute;
    background-color: #fff;
    flex-direction: column;
    gap: 5px;
    width: fit-content;
    z-index: 2;
    top: 100px;
    right: 0px;
    padding: 10px;
  }
  a {
    display: inline-block;
    margin-right: 20px;
  }

  .nav {
    justify-content: space-between;
    align-items: center;
  }

  .left-container {
    width: 100%;
  }
  .right-container {
    display: none;
  }
  .booksClubCard {
    width: 95%;
    margin: 1%;
    border-radius: 4px;
    border: 1.5px solid #ffac33;
    background: #fffef9;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 2px;
  }

  .pyramid {
    display: block;
  }
  .pyramid-desc {
    width: 97%;
    padding: 1%;
    font-size: medium;
    color: #999;
    margin: 1%;
  }
  .pyramid-experiment {
    width: 100%;
    display: flex;
  }
  .pyramid-exp-card {
    width: 97%;
    margin: 1%;
    /* height: 350px;
    border-radius: 5px;
    border: 1.5px solid #ffac33;
    background: #fff;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  }
  .event-card {
    width: 47%;
    margin: 10px;
    /* height: 360px; */
    border-radius: 5px;
    border: 1.5px solid #ffac33;
    background: #fff;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
  .give-msg {
    margin-top: 4%;
    margin-left: 1%;
    margin-right: 1%;
    font-size: 1rem;
  }
  .donation-type {
    margin-top: 4%;
    margin-left: 3%;
    font-size: 0.9rem;
    font-weight: initial;
  }
  .quote {
    display: none;
  }
  .contact-container {
    grid-template-columns: repeat(2, 1fr); /* 2 cards in a row */
  }
  .meditationPosture {
    width: 100%;
    height: 100%;
  }
  .media-container {
    display: block;
    gap: 1%;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .atlZelle {
    width: 70%;
    /* height: 10%; */
    margin: 1%;
  }
  .quote-main {
    display: block;
    border: 1px solid #bcbcbc;
    padding: 1%;
  }

  .quote-flier {
    width: 99%;
    margin-right: 5%;
  }

  .quote-content {
    font-size: 1rem;
  }

  .donation-section {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  .donation-qr {
    display: flex;
    text-align: center;
    align-items: center;
  }
  .paypal-icon {
    vertical-align: middle;
    margin-right: 5px;
    width: 20%;
  }
  .qr-code {
    width: 60%;
    height: auto;
  }
  .video-responsive,
  .card-video-responsive {
    height: 400px;
    width: 97%;
  }
}

/* Media query for mobile version */
@media screen and (max-width: 479px) {
  .programcard {
    width: 98%;
    border-radius: 5px;
    border: 1.5px solid #ffac33;
    background: #fff;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 1%;
  }
  .event-card {
    width: 95%;
    margin: 2%;
    /* height: 400px; */
    border-radius: 5px;
    border: 1.5px solid #ffac33;
    background: #fff;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  .donation-box {
    margin-top: 2%;
    margin-bottom: 2%;
    width: 98%;
    height: fit-content;
    background-color: #056eae;
    border-radius: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
  }
  .vl-donation {
    display: none;
  }
  .give-msg {
    font-size: 1rem;
  }
  .donation-type {
    margin-top: 40px;
    margin-left: 20px;
    font-size: 1rem;
  }
  .btn-donate {
    width: 150px;
    height: 60px;
    background-color: #f4900c;
    border: 2px solid #fff;
    border-radius: 5px;
    color: #fff;
    letter-spacing: 5px;
    font-weight: bold;
    margin: 12%;
  }

  .vol {
    display: block;
  }
  .vol-text {
    margin-left: 1%;
    font-size: 0.9rem;
    padding-left: 5px;
    justify-content: space-evenly;
  }
  .btn-vol {
    display: none;
    width: 150px;
    height: 60px;
    background-color: #0091e9;
    border: 2px solid #fff;
    border-radius: 5px;
    margin: 5% 30%;
    color: #fff;
    letter-spacing: 5px;
    font-weight: bold;
  }

  .pyramid {
    display: block;
  }
  .pyramid-desc {
    width: 100%;
    padding: 1%;
    font-size: medium;
    color: #999;
    margin: 1%;
  }
  .pyramid-experiment {
    display: block;
    width: 100%;
  }

  .pyramid-exp-card {
    width: 97%;
    margin: 1%;
    /* height: 350px;
    border-radius: 5px;
    border: 1.5px solid #ffac33;
    background: #fff;
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.25); */
  }

  .quote {
    display: none;
  }
  .mobile-version {
    display: block;
  }
  .dev-by-bar {
    display: none;
  }
  .dev-by {
    display: block;
    justify-content: center;
    text-align: center;
  }
  .Container-title {
    margin: 1%;
    font-size: 1rem;
    padding: 10px;
  }
  .read-more {
    display: inline;
  }

  .long-text {
    height: 0;
    overflow: hidden;
    display: inline-block;
  }
  .expanded {
    height: auto;
  }
  .flyer {
    width: 92%;
    height: auto;
  }
  .contact-container {
    grid-template-columns: 1fr;
  }
  .meditationPosture {
    width: 100%;
    height: 100%;
  }
  .media-container {
    display: block;
    gap: 1%;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
  }
  .atlZelle {
    width: 90%;
    /* height: 10%; */
    margin: 1%;
  }
  .spiritual-books {
    justify-content: center;
  }
  .pssm-logo {
    width: 9rem;
  }
  .quote-main {
    display: block;
    border: 1px solid #bcbcbc;
    padding: 1%;
  }
  .quote-flier {
    width: 100%;
  }
  .quote-content {
    font-size: 1rem;
  }
  .donation-section {
    display: block;
    width: 100%;
    margin: 0 auto;
    text-align: left;
  }
  .donation-qr {
    display: block;
    text-align: center;
    align-items: center;
  }
  .paypal-icon {
    vertical-align: middle;
    margin-right: 5px;
    width: 20%;
  }
  .qr-code {
    width: 60%;
    height: auto;
  }

  .testimonials-card {
    display: block;
    margin: 5% 7%;
    border: 1px solid #ffac33;
    padding: 1%;
    font-size: 1rem;
    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }
  .testimonials-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .testimonials-image {
    display: block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 16px;
    align-items: center;
  }

  .testimonials-details {
    display: block;
  }

  .testimonials-name {
    text-align: center;
    padding: 5%;
    font-size: 1rem;
    margin: 0;
  }
  .nav-heading {
    flex: 2;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    color: #9b260b;
    margin: 0;
    padding: 1%;
  }
  .video-responsive,
  .card-video-responsive {
    height: 100%;
  }
}
