.pyramid-project {
  border: 1.5px solid #bcbcbc;
  margin: 5px;
  background-color: #f0f0f0;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.pyramid-project-title {
  background-color: #410698;
  color: white;
  padding: 15px;
  width: 97%;
  text-align: center;
  font-size: 1.4rem;
  font-weight: bold;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pyramid-video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Ensures it takes the full width */
  height: auto; /* Adjust as needed */
  padding: 20px; /* Optional padding */
}

.pyramid-video-responsive {
  width: 90%; /* Adjust width as needed */
  max-width: 70%; /* Restricts excessive stretching */
  aspect-ratio: 16 / 9; /* Maintains aspect ratio */
  border: none; /* Removes default iframe border */
}

.pyramid-paragraph {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: #333;
  line-height: 1.8;
  text-align: justify;
  background: linear-gradient(180deg, rgb(74, 158, 241), #e9ecef);
  padding: 20px;
  border-radius: 10px;
  max-width: 70%;
  margin: 20px auto;
}
.more-info {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-top: 15px;
}

.more-info a {
  text-decoration: none;
  color: white;
  background: linear-gradient(135deg, #ff7e5f, #feb47b);
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;

  transition: all 0.3s ease-in-out;
}

.more-info a:hover {
  background: linear-gradient(135deg, #feb47b, #ff7e5f);
  transform: scale(1.1);
}

/* .pyramid-video-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
  width: 100%;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.pyramid-video-responsive {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 10px;
} */
.donate-btn-div {
  color: red;
}

/* Center the Donate Button */
.donate-now {
  text-align: center;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  margin-top: 20px;
}

.donate-us {
  margin-bottom: 2%;
}

.donate-rest-of-world > .nav-donate-btn {
  background-color: #e94040;
  margin-bottom: 1%;
}
/* Donate Now Button Styling */
.donate-now a {
  background-color: #ff5722; /* Bright orange color */
  color: white; /* White text */
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 12px 28px;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: inline-block;
}

/* Hover Effect */
.donate-now a:hover {
  background-color: #e64a19; /* Darker orange */
  transform: scale(1.05); /* Slight zoom effect */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
}

/* Mobile Responsive Styles */
@media screen and (max-width: 479px) {
  .pyramid-project {
    margin: 10px;
    padding: 15px;
  }

  .pyramid-project-title {
    font-size: 1rem;
    padding: 12px;
  }

  .pyramid-video-wrapper {
    width: 99%; /* Make video take up more width on smaller screens */
    /* padding-top: 56.25%; */
  }
  .donate-now a {
    font-size: 0.8rem;
    padding: 10px 24px;
    width: 80%; /* Make button take full width on small screens */
    text-align: center;
  }
  .pyramid-video-responsive {
    max-width: 99%; /* Restricts excessive stretching */
  }
  .pyramid-paragraph {
    max-width: 99%;
  }
}
